#picker-container .react-datepicker {
    background: #354153;
    color: #edf1fd;
    font-family: "Roboto300", "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #354153;
    border-radius: 8px;
}

#picker-container .react-datepicker-wrapper {
    width: 100%;
}

#picker-container .react-datepicker__header {
    background: #354153;
    color: #edf1fd;
    border-radius: 8px 8px 0 0;
    border-bottom-color: #1f2937;
}

#picker-container .react-datepicker__month-container {
    border-bottom: 1px solid #1f2937;
}

#picker-container .react-datepicker__triangle {
    background: #354153;
}

#picker-container .react-datepicker__current-month {
    color: #fff;
}

#picker-container .react-datepicker__day-name {
    color: #fff;
}

#picker-container .react-datepicker__day {
    color: #fff;
    border-radius: 2px;
    border: 1px solid transparent;
}

#picker-container .react-datepicker__day--selected {
    background-color: #ff4f66;
}

#picker-container .react-datepicker__day--in-range {
    background-color: #ff4f66;
}

#picker-container .react-datepicker__triangle::before {
    border-bottom-color: #354153;
}

#picker-container .react-datepicker__triangle::after {
    border-bottom-color: #354153;
}

#picker-container .react-datepicker__day:hover {
    border: 1px solid #ff4f66;
    background: none;
    box-sizing: border-box;
}

#picker-container .react-datepicker__day--in-selecting-range {
    background-color: #5d4457;
}

#picker-container .react-datepicker__day--excluded,
#picker-container .react-datepicker__day--excluded:hover {
    color: #9ba1b2;
    border: 1px solid transparent;
}

#picker-container .react-datepicker__input-container {
    cursor: pointer;
    caret-color: transparent;
}
