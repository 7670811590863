@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lexend300", "Open Sans", sans-serif;
    font-size: 16px;
    color: theme("colors.int-white-secondary");
    background: theme("colors.int-gray-main");
}

input {
    all: unset;
    color: #edf1fd;
    line-height: 14px;
    font-size: 16px;
    font-family: "Lexend400", "Open Sans", sans-serif;
    border: 1px solid #2a3143;
    box-sizing: border-box;
}

textarea {
    all: unset;
    color: #edf1fd;
    line-height: 14px;
    font-size: 16px;
    font-family: "Lexend400", "Open Sans", sans-serif;
    border: 1px solid #2a3143;
    box-sizing: border-box;
}

/* For Firefox */
@-moz-document url-prefix("") {
    input:focus,
    input:hover,
    textarea:focus,
    select:focus {
        outline: none;
    }

    #menu-scroll,
    #help-scroll,
    #bold-scroll,
    #thin,
    #primary-scroll {
        scrollbar-color: #9ba1b2 rgb(1 1 1 / 0%);
        scrollbar-width: thin;
    }
}

input::placeholder {
    color: #9ba1b2;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: all 0s 5000s ease-in-out;
    border: none !important;
}

a {
    text-decoration: none;
}

/* Gradients */
.primary-gradient-horizontal {
    background: linear-gradient(90deg, #ff396d 0%, #ed7048 100%);
}

.primary-gradient-vertical {
    background: linear-gradient(0deg, #ff396d -4.5%, #ed7048 100%);
}

/* Primary */
#primary-scroll::-webkit-scrollbar {
    width: 4px;
}

#primary-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #354153;
    border-radius: 10px;
}

#primary-scroll::-webkit-scrollbar-thumb {
    background: #9ba1b2;
    border-radius: 8px;
}

/* Thin */
#thin::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

#thin::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #354153;
    border-radius: 4px;
}

#thin::-webkit-scrollbar-thumb {
    background: #9ba1b2;
    border-radius: 4px;
}

/* Bold */
#bold-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

#bold-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #354153;
    border-radius: 4px;
}

#bold-scroll::-webkit-scrollbar-thumb {
    background: #9ba1b2;
    border-radius: 4px;
}

/* Menu */
#menu-scroll::-webkit-scrollbar-thumb {
    background: #9ba1b2;
    border-radius: 8px;
}

#menu-scroll::-webkit-scrollbar {
    width: 4px;
}

/* Help */
#help-scroll::-webkit-scrollbar {
    width: 2px;
}

#help-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1f2937;
    border-radius: 10px;
}

#help-scroll::-webkit-scrollbar-thumb {
    background: #9ba1b2;
    border-radius: 4px;
}

/* fix highlighting images when double-clicking anywhere on the page */
img {
    user-select: none;
}

/* fix input type number disallow arrow up and down */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
