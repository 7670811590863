@keyframes bounce {
    50% { transform: translateY(-5px); }
    100% { transform: translateY(5px); }
}

.bounce div {
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background-color: #354153;
    animation: bounce 1s ease-in-out infinite;
    position: absolute;
    top: 0;
    transform: translateY(5px);
}

.bounce div:first-of-type {
    left: -15px;
    animation-delay: 0.2s;
}

.bounce div:nth-of-type(2) {
    animation-delay: 0.1s;
}

.bounce div:last-of-type {
    left: 15px;
}
