@font-face {
    font-family: "Lexend300";
    src:
        local("Lexend"),
        url("./Lexend/Lexend-Light.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Lexend400";
    src:
        local("Lexend"),
        url("./Lexend/Lexend-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Lexend500";
    src:
        local("Lexend"),
        url("./Lexend/Lexend-Medium.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Lexend600";
    src:
        local("Lexend"),
        url("./Lexend/Lexend-SemiBold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Lexend700";
    src:
        local("Lexend"),
        url("./Lexend/Lexend-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Righteous400";
    src:
        local("Righteous"),
        url("./Righteous/Righteous-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "SourceSansPro400";
    src:
        local("SourceSansPro"),
        url("./SourceSansPro/SourceSansPro-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "SourceSansPro700";
    src:
        local("SourseSansPro"),
        url("./SourceSansPro/SourceSansPro-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Inter400";
    src:
        local("Inter"),
        url("./Inter/Inter-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Inter500";
    src:
        local("Inter"),
        url("./Inter/Inter-Medium.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Inter600";
    src:
        local("Inter"),
        url("./Inter/Inter-SemiBold.ttf") format("truetype");
    font-display: swap;
}
