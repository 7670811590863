@keyframes rotate {
    100% {
        transform: translate(0) rotate(360deg);
    }
}

.icon-animated {
    width: 24px;
    height: 24px;
    animation: rotate 1s ease-out;
}
