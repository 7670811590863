@keyframes clockwise {
    to {
        transform: rotate(360deg) translateZ(0);
    }
}

.spin {
    background-color: transparent;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow:
        12px -12px #ba4052,
        17px 0 #ff4f66,
        12px 12px #ff4f66,
        0 17px #5f2d38,
        -12px 12px #76323f,
        -17px 0 #76323f,
        -12px -12px #8d3645,
        0 -17px #a43b4b;
    animation: clockwise 0.75s steps(8, end) infinite;
}
